@import "~@/assets/scss/abstracts.scss";

.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
.icon-pop-close {
	display: flex;
	position: relative;
	width: 24px;
	height: 24px;
	align-items: center;
	justify-content: center;
	&::after,
	&::before {
		content: '';
		position: absolute;
		width: 1.5px;
		height: 29px;
		top: 50%;
		left: 50%;
		background-color: #fff;
	}
	&::after {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	&::before {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
}
.lightbox {
	// display: flex;
	position: absolute;
	width: 80%;
	max-width: 1200px;
	max-height: 100vh;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	// position: relative;
	// flex-direction: column;
	// justify-content: center;
	@include mobile {
		width: 100%;
		padding: 15px;
	}
	.vod-wrap {
		width: 100%;
	}
	.button-lightbox-close {
		display: flex;
		width: 24px;
		height: 24px;
	}
	&-header {
		display: flex;
		padding-bottom: 20px;
		align-items: center;
		justify-content: flex-end;
		flex: 0 0 24px;
	}
	&-body {
		display: block;
		// overflow: hidden;
		flex: 1 1 calc(100% - 100px);
	}
	&-container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&-img {
		// display: flex;
		// width: 100%;
		// height: 100%;
		// max-height: calc(100vh - 150px);
		overflow: hidden;
		align-items: center;
		justify-content: center;
		border-radius: 15px;
		img {
			width: 100%;
			height: initial;
			max-height: calc(100vh - 150px);
			object-fit: contain;
		}
	}
	&-text {
		display: block;
		max-height: 41px;
		margin-top: 24px;
		font-size: 16px;
		line-height: 1.25;
		color: #fff;
		@include text-ellipsis-2;
	}
	.swiper-slide {
		display: flex;
		opacity: 0;
		visibility: hidden;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		&-active {
			opacity: 1;
			visibility: visible;
		}
	}

	.swiper-buttons-container {
		.swiper-button-next,
		.swiper-button-prev {
			@include mobile {
				width: 36px;
				height: 36px;
				border-radius: 50%;
				background-color: rgba(255, 255, 255, 0.7);
				transform: scale(1);
			}
			&::after {
				border-color: #fff;
				@include mobile {
					top: 50%;
					left: 50%;
					width: 13px;
					height: 13px;
					border-color: #000;
					border-width: 1px;
				}
			}
		}
		.swiper-button-prev {
			left: -45px;
			@include mobile {
				left: 25px;
				&::after {
					transform: translate(-30%, -50%) rotate(225deg);
				}
			}
		}
		.swiper-button-next {
			right: -45px;
			@include mobile {
				right: 25px;
				&::after {
					transform: translate(-70%, -50%) rotate(45deg);
				}
			}
		}
	}
}
