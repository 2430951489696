// @use 'derived-variables';
@use 'variables';
// @use 'sass:math';
// 브레이크 포인트
// desktop : 1024px --> @media min-width
// tablet : 768px ~ 1023px --> @media max-width
// mobile : ~ 767px --> @media max-width

// 그외 임의 브레이크포인트 from, until 사용
// min-width usage : @include from(1350) { ~~ }
// max-width usage : @include until(1350) { ~~ }

@mixin small {
	@media screen and (max-width: variables.$small) {
		@content;
	}
}
@mixin mobile {
	@media screen and (max-width: variables.$mobile) {
		@content;
	}
}
@mixin tablet {
	@media screen and (max-width: (variables.$desktop - 1px)), print {
		@content;
	}
	// @media screen and (min-width: $tablet), print {
	// 	@content;
	// }
}
@mixin tablet-only {
	@media screen and (min-width: variables.$tablet) and (max-width: (variables.$desktop - 1px)) {
		@content;
	}
}

@mixin touch {
	@media screen and (max-width: (variables.$desktop - 1px)) {
		@content;
	}
}

@mixin desktop {
	@media screen and (min-width: variables.$desktop) {
		@content;
	}
}

@mixin desktop-only {
	@if variables.$widescreen-enabled {
		@media screen and (min-width: variables.$desktop) and (max-width: (variables.$widescreen - 1px)) {
			@content;
		}
	}
}

@mixin until-widescreen {
	@if variables.$widescreen-enabled {
		@media screen and (max-width: (variables.$widescreen - 1px)) {
			@content;
		}
	}
}

@mixin widescreen {
	@if variables.$widescreen-enabled {
		@media screen and (min-width: variables.$widescreen) {
			@content;
		}
	}
}

@mixin widescreen-only {
	@if variables.$widescreen-enabled and variables.$fullhd-enabled {
		@media screen and (min-width: variables.$widescreen) and (max-width: ($fullhd - 1px)) {
			@content;
		}
	}
}

@mixin until-fullhd {
	@if variables.$fullhd-enabled {
		@media screen and (max-width: ($fullhd - 1px)) {
			@content;
		}
	}
}

@mixin fullhd {
	@if variables.$fullhd-enabled {
		@media screen and (min-width: $fullhd) {
			@content;
		}
	}
}
@mixin from($device) {
	@media screen and (min-width: ($device - 1px + 1px)) {
		@content;
	}
}

@mixin until($device) {
	@media screen and (max-width: ($device - 1px)) {
		@content;
	}
}

@mixin overflow-touch {
	-webkit-overflow-scrolling: touch;
}

@mixin unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

%unselectable {
	@include unselectable;
}
@mixin noimg {
	background-color: variables.$thumbColor;
	// background-image: url(../images/noimg.svg);
	background-position: 50% 50%;
	background-size: 56px 43px;
	background-repeat: no-repeat;
}
@mixin thumbnail {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	object-fit: cover;
}
@mixin ico-down {
	&::before {
		@include ico;
		content: ico-variables.$ico-chevron-down;
		font-size: 26px;
	}
}
@mixin arrow($color: transparent) {
	border: 2px solid $color;
	border-radius: 2px;
	border-right: 0;
	border-top: 0;
	content: ' ';
	display: block;
	height: 0.625em;
	margin-top: -0.4375em;
	pointer-events: none;
	position: absolute;
	top: 50%;
	transform: rotate(-45deg);
	transform-origin: center;
	width: 0.625em;
}

%arrow {
	@include arrow;
}

@mixin block($spacing: variables.$block-spacing) {
	&:not(:last-child) {
		margin-bottom: $spacing;
	}
}

%block {
	@include block;
}

@mixin checkbox-label {
	display: block;
	float: left;
	position: relative;
	padding: 0px 0 0 28px;
	font-size: 12px;
	line-height: 1.2;
	/*font-weight: 400;*/
	/*line-height: 21px;*/
	// cursor: pointer;
	color: variables.$grey-light;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

@mixin placeholder {
	$placeholders: ':-moz' ':-webkit-input' '-moz' '-ms-input';

	@each $placeholder in $placeholders {
		&:#{$placeholder}-placeholder {
			@content;
		}
	}
}

@mixin delete {
	@extend %unselectable;

	position: relative;
	display: inline-block;
	width: 25px;
	max-width: 25px;
	min-width: 25px;
	height: 25px;
	min-height: 25px;
	max-height: 25px;
	// appearance: none;
	// background-color: rgba(255, 255, 255, 1);
	border: none;
	border-radius: 100%;
	cursor: pointer;
	pointer-events: auto;
	flex-grow: 0;
	flex-shrink: 0;
	font-size: 0;
	// outline: none;
	vertical-align: top;

	&::before,
	&::after {
		background-color: #606c88;
		content: '';
		display: block;
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
		transform-origin: center center;
	}

	&::before {
		height: 1px;
		width: 75%;
	}

	&::after {
		height: 75%;
		width: 1px;
	}

	&:hover,
	&:focus {
		background-color: rgba(96, 108, 136, 0.2);
		&::before,
		&::after {
			background-color: #fff;
		}
	}

	&:active {
		background-color: #606c88;
		&::before,
		&::after {
			background-color: #fff;
		}
	}

	// Sizes
	&.is-small {
		width: 16px;
		min-width: 16px;
		max-width: 16px;
		height: 16px;
		min-height: 16px;
		max-height: 16px;
	}

	&.is-medium {
		width: 24px;
		min-width: 24px;
		max-width: 24px;
		height: 24px;
		min-height: 24px;
		max-height: 24px;
	}

	&.is-large {
		width: 46px;
		min-width: 46px;
		max-width: 46px;
		height: 46px;
		min-height: 46px;
		max-height: 46px;
		@include mobile {
			width: 40px;
			min-width: 40px;
			max-width: 40px;
			height: 40px;
			min-height: 40px;
			max-height: 40px;
		}
	}
}

%delete {
	@include delete;
}

@keyframes spinAround {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(359deg);
	}
}
@keyframes spinAroundC {
	from {
		transform: rotate(359deg);
	}

	to {
		transform: rotate(0deg);
	}
}
@mixin loader {
	animation: spinAround 500ms infinite linear;
	border: 2px solid variables.$grey-light;
	border-radius: 50%;
	border-right-color: transparent;
	border-top-color: transparent;
	content: '';
	display: block;
	height: 1em;
	position: relative;
	width: 1em;
}

%loader {
	@include loader;
}

@mixin overlay($offset: 0) {
	bottom: $offset;
	left: $offset;
	position: absolute;
	right: $offset;
	top: $offset;
}

%overlay {
	@include overlay;
}

@mixin word-wrap {
	word-break: keep-all;
	word-wrap: break-word;
}

// 말줄임 설정
@mixin text-ellipsis {
	overflow: hidden;
	display: block;
	word-wrap: normal;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@mixin text-ellipsis-2 {
	display: -webkit-box;
	overflow: hidden;
	word-break: break-all;
	word-wrap: break-word;
	text-overflow: ellipsis;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

@mixin text-ellipsis-3 {
	display: -webkit-box;
	overflow: hidden;
	word-break: break-all;
	word-wrap: break-word;
	text-overflow: ellipsis;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}

@mixin text-ellipsis-4 {
	display: -webkit-box;
	overflow: hidden;
	word-break: break-all;
	word-wrap: break-word;
	text-overflow: ellipsis;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
}

@mixin disabled {
	cursor: not-allowed;
	pointer-events: none;
}

@mixin clearfix {
	&::after {
		clear: both;
		content: ' ';
		display: table;
	}
}

@mixin center($width, $height: 0) {
	position: absolute;

	@if $height != 0 {
		left: calc(50% - (#{$width} / 2));
		top: calc(50% - (#{$height} / 2));
	} @else {
		left: calc(50% - (#{$width} / 2));
		top: calc(50% - (#{$width} / 2));
	}
}

@mixin transition($target, $second) {
	transition: $target $second cubic-bezier(0.16, 0.04, 0.03, 0.95);
}

@mixin page-tit {
	font-size: 35px;
	// font-size: 46px;
	font-weight: 400;
	// line-height: 1.22;
	//letter-spacing: -1px;
	color: #303030;
	background-color: #fff;
	.button-link-back {
		display: none;
		&::after {
			content: '';
			display: inline-block;
			@include sprite($icon-page-back);
		}
	}
	@include mobile {
		display: flex;
		position: fixed;
		width: 100%;
		height: 48px;
		top: 0;
		left: 0;
		padding: 0 20px;
		z-index: 10;
		font-size: 20px;
		/*font-weight: 600;*/
		/*line-height: 48px;*/
		//letter-spacing: -0.5px;
		.button-link-back {
			display: inline-block;
			margin-right: 16px;
			font-size: 0;
		}
	}
}

@mixin position($p: null, $t: null, $b: null, $l: null, $r: null, $w: null, $h: null) {
	position: $p;
	top: $t;
	bottom: $b;
	left: $l;
	right: $r;
	width: $w;
	height: $h;
}

@mixin aster {
	content: '*';
	display: inline-block;
	color: variables.$red;
	font-size: 12px;
	font-weight: 700;
	vertical-align: text-top;
}
@mixin asterisk($dir) {
	@if $dir == 'before' {
		&::before {
			@include aster;
			margin-right: 3px;
		}
	} @else {
		&::after {
			@include aster;
			margin-left: 3px;
		}
	}
	// @include asterisk(before || null)
	// null = after
}

@mixin ico {
	font-family: 'ico' !important;
	speak: never;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
}

@mixin icon {
	display: inline-block;
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: contain;
	vertical-align: middle;
}

@mixin object-fit {
	display: inline-flex;
	position: relative;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

// flex 사용시 include flex(row || column)
@mixin flex($dir) {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: $dir;
	-ms-flex-direction: $dir;
	flex-direction: $dir;
}
// v : vertical or h:horizontal
@mixin scrollbar($hover: null, $mode: null) {
	@if $hover == 'hover' {
		&:hover::-webkit-scrollbar {
			display: block;
		}
	}

	&::-webkit-scrollbar {
		@if $hover == 'hover' {
			display: none;
		}
		@if $mode == 'h' {
			height: 6px;
		} @else {
			width: 6px;
		}

		&-button {
			display: block;
			width: 6px;
			height: 6px;
			&:-webkit-decrement:-webkit-start {
				background-color: lightblue;
				border: 2px solid black;
			}

			&:-webkit-increment:-webkit-start {
				background-color: lightgreen;
				border: 2px solid black;
			}

			&:-webkit-decrement:-webkit-end {
				background-color: orange;
				border: 2px solid black;
			}

			&:-webkit-increment:-webkit-end {
				background-color: brown;
				border: 2px solid black;
			}

			&:hover {
				background-color: black !important;
			}
		}

		&-thumb {
			margin: 3px 0;
			border-radius: 6px;
			background-color: rgba(0, 0, 0, 0.5);
			&:hover {
				background-color: rgba(0, 0, 0, 0.7);
			}

			&:active {
				background-color: rgba(0, 0, 0, 0.9);
			}
		}

		&-track {
			border-radius: 6px;
			background-color: rgba(0, 0, 0, 0.05);
		}
	}
}
